import React, { Component } from "react";
import nyanVoting from "./contracts/NyanVoting.json";
import nyanVotingData from "./contracts/VotingDataLayout.json"
import NyanToken from "./contracts/NyanToken.json";
import CatnipToken from "./contracts/CatnipToken.json";
import Governance from "./contracts/NyanGovernance.json";
import {getWeb3Var} from "./shared";


export default class Pump extends Component {
  state = {
    voteSection: "Fund",
    voteOption: "Info",
    currentTypeSelected: "addresses",
    votingRound: 0,
    currentVotingStartBlock: 0,
    currentVotingEndBlock: 0,
    lockedNyan: 0,
    proposals: [],
    currentBidAddress: "",
    currentInterface: "erc20",
    currentFunction: "",
    interfaces: [
      {
        code: 'erc20',
        functions: ["", "totalSupply", "balanceOf", "transfer", "allowance", "approve", "transferFrom"]
      },
      {
        code: 'fund',
        functions: ["setVotingAddress"]
      }
    ],
    currentParams: {
      addresses: [],
      integers: [],
      strings: [],
      bytes: [],
      chain: []
    },
    bidInfoLoaded: false,
    currentBidInfo: {
      mainBid: [],
      chain: []
    },
    stringInput: "",
    intInput: 0,
    pushedParamLength: 0,
    currentBid: {},
    chainLoaded: false,
    hasBid: false,
    currentChainBid: "",
    currentChainBidData: {},
    catnipApproved: 0,
    nyanApproved: 0,
    isNyanApproving: false,
    nyanGovApproved: 0,
    isNyanGovApproving: false,
    isCatnipApproving: false,
    voteAmount: 0,
    proposalId: "",
    currentEthBlock: 0,
    myNyanVotes: 0,
    myGovernanceVotes: 0,
    governanceQuestion: {
      question: "Loading governance question...",
      options: [],
      optionImgs: [],
      optionVotes: [],
      voteEndBlock: 0
    },
    isWithdrawinGovNyan: false,
    currentChainFunction: '',
    contractList: [
      "0x5DAbFE3820a99B42201c77925cb39bb0877a645c"
    ]
  };
  
  getBidInfo = async (interfaceName, functionName, addresses, integers, strings, bytes) => {
    let arr = [];
    this.setState({bidInfoLoaded: false});
    if (interfaceName === 'erc20') {
      let info = await this.getERC20Info(functionName, addresses, integers, strings, bytes);
      let tempInfo = this.state.currentBidInfo;
      tempInfo.mainBid[0] = <p className="bid-info">{info}</p>;
      this.setState({currentBidInfo: tempInfo, bidInfoLoaded: true})
    }
    if (interfaceName === 'uniV2') {
      let info = await this.getUniV2Info(functionName, addresses, integers, strings, bytes);
      let tempInfo = this.state.currentBidInfo;
      tempInfo.mainBid[0] = <p className="bid-info">{info}</p>;
      this.setState({currentBidInfo: tempInfo, bidInfoLoaded: true})
    }
    return arr;
  } 

  getChainBidInfo = async (interfaceName, functionName, addresses, integers, strings, bytes) => {
    let arr = [];
    this.setState({bidInfoLoaded: false});
    if (interfaceName === 'erc20') {
      console.log(functionName);
      let info = await this.getERC20Info(functionName, addresses, integers, strings, bytes);
      let tempInfo = this.state.currentBidInfo;
      tempInfo.chain[0] = <p className="bid-info">{info}</p>;
      this.setState({currentBidInfo: tempInfo, bidInfoLoaded: true})
    }
    if (interfaceName === 'uniV2') {
      let info = await this.getUniV2Info(functionName, addresses, integers, strings, bytes);
      let tempInfo = this.state.currentBidInfo;
      tempInfo.chain[0] = <p className="bid-info">{info}</p>;
      this.setState({currentBidInfo: tempInfo, bidInfoLoaded: true})
    }
    return arr;
  }

  getERC20Info = async (functionName, addresses, integers, strings, bytes) => {
    console.log(functionName);
    switch(functionName) {
        case "approve":
          let functionSig = new this.web3.eth.Contract(
            NyanToken.abi,
            addresses[0]
            );
          console.log(addresses[0]);
          console.log(functionSig);
          let name = await functionSig.methods.name().call();
          let integer = integers[0].toString();
          console.log(integers);
          console.log(name);
          return `Approving ${this.web3.utils.fromWei(integer)} ${name} for Contract: ${addresses[1]}`
          break;
        case "univ2":
          // code block
          break;
        default:
          // code block
      }
  }

  getUniV2Info = async (functionName, addresses, integers, strings, bytes) => {
    let functionSig;
    let functionSig2;
    let name;
    let name1;
    switch(functionName) {
      
      case "swapExactTokensForTokens":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        functionSig2 = new this.web3.eth.Contract(NyanToken.abi,addresses[2]);
        name = await functionSig.methods.name().call();
        try {
          name1 = await functionSig2.methods.name().call();
        } catch {
          name1 = "error"
        }
        return `Swaps ${this.web3.utils.fromWei(integers[0])} ${name} for as much ${name1} as possible`
        break;
      case "swapExactTokensForETH":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        name = await functionSig.methods.name().call();
        return `Swaps ${this.web3.utils.fromWei(integers[0])} ${name} for as much ETH as possible`
        break;
      case "addLiquidity":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        functionSig2 = new this.web3.eth.Contract(NyanToken.abi,addresses[1]);
        name = await functionSig.methods.name().call();
        name1 = await functionSig2.methods.name().call();
        return `Adds ${integers[0]} ${name} and ${integers[1]} ${name1} to lquidity pair`
        break;
      case "addLiquidityETH":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        name = await functionSig.methods.name().call();
        return `Adds ${integers[0]} ${name} and ${integers[1]} ETH to lquidity pair`
        break;
      case "removeLiquidity":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        functionSig2 = new this.web3.eth.Contract(NyanToken.abi,addresses[1]);
        name = await functionSig.methods.name().call();
        name1 = await functionSig2.methods.name().call();
        return `Removes ${integers[0]} ${name} and ${integers[1]} ${name1} from lquidity pair`
        break;
      case "removeLiquidityETH":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        name = await functionSig.methods.name().call();
        return `Removes ${integers[0]} ${name} and ${integers[1]} ETH from lquidity pair`
        break;
      case "removeLiquidityETHSupportingFeeOnTransferTokens":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[0]);
        name = await functionSig.methods.name().call();
        return `Removes ${integers[0]} Liqiudity tokens from ${name}`
        break;
      case "swapExactETHForTokens":
        functionSig = new this.web3.eth.Contract(NyanToken.abi,addresses[1]);
        name = await functionSig.methods.name().call();
        if (!integers[0]) {
          integers[0] = 0;
        }
        return `Swapping ${this.web3.utils.fromWei(integers[0].toString())} ETH for as many ${name} as possible.`
        break;
      default:
        // code block
    }
  }
  
  handleClick = () => {
    this.props.toggle();
  };

  updateParamStringInput(e) {
    this.setState({stringInput: e})
 }

 handleInterfaceSelect(e) {
   this.setState({currentInterface: e})
 }

  handleChainInterfaceSelect(e, i) {
    let temp = this.state.currentParams;
    temp.chain[i].currentInterface = e;
    this.setState({currentParams: temp});
  }

  handleFunctionSelect(e) {
    this.setState({currentFunction: e})
  }

  handleChainFunctionSelect(e, interF, i) {
    let temp2 = this.state.currentParams;
    temp2.chain[i].currentFunction = e;
    this.setState({currentParams: temp2});
  }

  updateParamIntInput(e) {
    this.setState({intInput: e});
  }

  updateChainParamIntInput(e) {

  }

  updateVoteInput(e) {
    this.setState({voteAmount: e.target.value})
  }

  changeVoteSection(section) {
    if (section === "Governance") {
      this.getGovernanceProposal();
    }
    this.setState({voteSection: section});
  }

  selectVoteOption = (option) => {
    if (option === "Vote") {
      this.getProposals();
    }
    if (option === "Bid") {
      this.setState({chainLoaded: false})
      this.getBid();
    }
    this.setState({voteOption: option});
  }

  selectTypeOption = (option) => {
    this.setState({currentTypeSelected: option});
    setTimeout(() => {
      if (this.state.voteOption === "Bid") {
        this.getPushedBidPropParams();
      }
      else if (this.state.voteOption === "Vote") {
        this.getPushedPropParams();
      }
    }, 100);
  }

  getVotingRound = async () => {
    let votingRound = await this.nyanVotingInstance.methods.currentVotingRound().call();
    this.setState({votingRound: votingRound});
  }

  getLockedNyan = async () => {
    // let lockedNyan = await this.nyanVotingInstance.methods.getBidNyanStaked(this.accounts[0]).call();
    // this.setState({lockedNyan: lockedNyan});
  }

  getVotingStartEndBlocks = async () => {
    let startBlock = await this.nyanVotingInstance.methods.currentVotingStartBlock().call();
    let endBlock = await this.nyanVotingInstance.methods.currentVotingEndBlock().call();
    this.setState({currentVotingStartBlock: startBlock, currentVotingEndBlock: endBlock});
  }

  getBid = async () => {
    let bids = await this.nyanVotingInstance.methods.getBid(this.accounts[0]).call();
    this.setState({
      currentInterface: bids[0],
      currentFunction: bids[1],
      currentParams: {
        addresses: bids[2],
        integers: bids[3],
        strings: bids[4],
        bytes: [],
        chain: bids[5]
        
      }
      
    });
    if (bids[0]) {
      let temp = this.state.currentParams;
      temp.chain = [];
      if (bids[5].length > 0) {
        for (let i = 0; i < bids[5].length; i++) {
          let chainBid = await this.nyanVotingInstance.methods.getChain(bids[5][i]).call();
          temp.chain.push({
            chainId: bids[5][i],
            currentInterface: chainBid[1],
            currentFunction: chainBid[2],
            addresses: chainBid[3],
            integers: chainBid[4],
            strings: [5],
            bytes: []
          })
        }
      }
      this.setState({currentParams: temp, chainLoaded: true, hasBid: true});
    }
  }

  getBidView = async (bidAddress) => {
    let bid = await this.nyanVotingInstance.methods.getBid(bidAddress).call();
    this.setState({currentBidAddress: bidAddress, currentBid: bid, hasBid: true})
    this.getBidInfo(bid[0], bid[1], bid[2], bid[3], bid[4], [])
  }

  closeBidView = async () => {
    this.setState({currentBidAddress: ""});
  }

  addChain() {
    let temp = this.state.currentParams;
    temp.chain.push({
      currentInterface: "",
      currentFunction: "",
      addresses: [],
      integers: [],
      strings: [],
      bytes: []
    });
    this.setState({
      currentParams: temp
    });
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  addChainToBid = async (index) => {
    let chainId;
    if (!this.state.currentParams.chain[index].chainId) {
      chainId = this.makeid(6);
    } else {
      chainId = this.state.currentParams.chain[index].chainId;
    }
    console.log(this.state.currentParams.chain[index]);
    let bidId = this.accounts[0].toString();
    try {
      let chainAdd = await this.nyanVotingInstance.methods
                            .addChainBid(
                                chainId,
                                bidId,
                                this.state.currentParams.chain[index].currentInterface,
                                this.state.currentParams.chain[index].currentFunction,
                                this.state.currentParams.chain[index].addresses,
                                this.state.currentParams.chain[index].integers,
                                this.state.currentParams.chain[index].strings,
                                this.state.currentParams.chain[index].bytes
                              ).send({
        from: this.accounts[0]
      });
     
    } catch (error) {
        console.log(error);
    }
  }


  loadChain() {
    var arr = [];
    for (let i = 0; i < this.state.currentParams['chain'].length; i++) {
      arr.push(
        <div className="">
                <div className="bid">
                  <label for="functionCode">Interface:</label>
                  {this.state.hasBid ?
                        <select onChange={event => this.handleChainInterfaceSelect(event.target.value, i)} id="functionCode" className="input-amount">
                        {/* <option label="ERC20">ERC20</option>
                        <option label="WETH">WETH</option>
                        <option label="Yearn">Yearn</option>
                        <option label="Uniswap">Uniswap</option> */}
                        {this.getChainInterfaces(i)}
                      </select>
                        :
                        <div></div>
                      }
                  
                  <label for="functionName">Function:</label>
                  <select onChange={event => this.handleChainFunctionSelect(event.target.value, this.state.currentParams.chain[i].currentInterface, i)} id="functionName" className="input-amount">
                    {this.getInterfaceChainFunctions(this.state.currentParams.chain[i].currentInterface, i)}
                  </select>

                  <h5>Info about function</h5>
                  <div className="var-box">
                    <div className="types">
                      <div onClick={() => this.selectTypeOption('addresses')} className={`types-op ${this.state.currentTypeSelected === "addresses"?"types-sel":null}`}>Addresses[]</div>
                      <div onClick={() => this.selectTypeOption('integers')} className={`types-op ${this.state.currentTypeSelected === "integers"?"types-sel":null}`}>Integers[]</div>
                      <div onClick={() => this.selectTypeOption('strings')} className={`types-op ${this.state.currentTypeSelected === "strings"?"types-sel":null}`}>Strings[]</div>
                      <div onClick={() => this.selectTypeOption('bytes')} className={`types-op ${this.state.currentTypeSelected === "bytes"?"types-sel":null}`}>Bytes[]</div>
                    </div>
                    <div className="values-area">
                      <div>
                        {this.state.chainLoaded ?
                          <div>
                            {this.getPushedChainParams(i)}
                          </div>
                          :
                          <div></div>
                        }
                      </div>
                     
                      {this.state.currentTypeSelected === "integers"? 
                        <input 
                        onChange={event => this.updateParamIntInput(event.target.value)}
                        className="param-input" type="number" id="lname" name="lname">
                        
                        </input> : null}
                      {this.state.currentTypeSelected != "integers"? 
                        <input 
                        id="non-int"
                        onChange={event => this.updateParamStringInput(event.target.value)}
                        className="param-input" type="text" id="lname" name="lname">

                        </input> : null}
                      <br></br>
                      {this.state.currentTypeSelected==="integers" ? <div className="add-value" onClick={event=> {this.pushChainParam(this.state.intInput, i)}}>Add Parameter</div> : null}
                      {this.state.currentTypeSelected!="integers" ? <div className="add-value" onClick={event=> {this.pushChainParam(this.state.stringInput, i)}}>Add Parameter</div> : null}
                    </div>
                  </div>
                  <div className="propose" onClick={() => this.addChainToBid(i)}>Push Chain</div>
                </div>
                <div className="v-bar"></div>
              </div>
      )
    }
    return arr;
  }

  getProposals = async () => {
    let proposals = await this.nyanVotingInstance.methods.getProposals().call();
    // let bids = await this.nyanVotingInstance.methods.getBid(proposals).call();
    // console.log(bids);
    
    this.setState({proposals: proposals});
  }

  proposeBid = async () => {
    
    try {
      let stakeRes = await this.nyanVotingInstance.methods
                            .proposeBid(
                                this.accounts[0].toString(),
                                this.state.currentInterface,
                                this.state.currentFunction,
                                this.state.currentParams.addresses,
                                this.state.currentParams.integers,
                                this.state.currentParams.strings,
                                this.state.currentParams.bytes
                              ).send({
        from: this.accounts[0]
      });
     
    } catch (error) {
        console.log(error);
    }
  }

  voteForBid = async (index) => {
    try {
      let stakeRes = await this.nyanVotingInstance.methods
                            .voteForBid(
                                this.state.proposals[index],
                                this.web3.utils.toWei(this.state.voteAmount)
                              ).send({
        from: this.accounts[0]
      });
    } catch (error) {

    }
  }

  getInterfaces() {
    var arr = [];
    for (let i = 0; i < this.state.interfaces.length; i++) {
        if (this.state.currentInterface === this.state.interfaces[i].code) {
          arr.push(<option selected key={i} value={this.state.interfaces[i].code}>{this.state.interfaces[i].code}</option>)
        } else {
          arr.push(<option key={i} value={this.state.interfaces[i].code}>{this.state.interfaces[i].code}</option>)
        }
    }
    
    return arr;
}

  getChainInterfaces(index) {
    var arr = [];
    for (let i = 0; i < this.state.interfaces.length; i++) {
        if (this.state.currentParams.chain[index].currentInterface === this.state.interfaces[i].code) {
          arr.push(<option selected key={i} value={this.state.interfaces[i].code}>{this.state.interfaces[i].code}</option>)
        } else {
          arr.push(<option key={i} value={this.state.interfaces[i].code}>{this.state.interfaces[i].code}</option>)
        }
    }
    
    return arr;
  }

  getInterfaceFunctions(interfaceCode) {
    var arr = [];
    for (let i = 0; i < this.state.interfaces.length; i++) {
      if (this.state.interfaces[i].code === interfaceCode) {

        for (let j = 0; j < this.state.interfaces[i].functions.length; j++) {
          if (this.state.currentFunction === this.state.interfaces[i].functions[j] ) {
            arr.push(<option selected key={j} value={this.state.interfaces[i].functions[j]}>{this.state.interfaces[i].functions[j]}</option>)
          } else {
            arr.push(<option key={j} value={this.state.interfaces[i].functions[j]}>{this.state.interfaces[i].functions[j]}</option>)
          }
        }
      }
    }
    return arr; 
  }

  setChainFunction(func, index) {
    let temp = this.state.currentParams;
    temp.chain[index].currentFunction = func;
    this.setState({currentParams: temp});
  }

  getInterfaceChainFunctions(interfaceCode, index) {
    var arr = [];
    for (let i = 0; i < this.state.interfaces.length; i++) {
      if (this.state.interfaces[i].code === interfaceCode) {
        
        for (let j = 0; j < this.state.interfaces[i].functions.length; j++) {
          if (this.state.currentParams.chain[index].currentFunction === this.state.interfaces[i].functions[j] ) {
            arr.push(<option selected key={j} value={this.state.interfaces[i].functions[j]}>{this.state.interfaces[i].functions[j]}</option>)
          } else {
            arr.push(<option  key={j} value={this.state.interfaces[i].functions[j]}>{this.state.interfaces[i].functions[j]}</option>)
          }
        }
      }
    }
    return arr; 
  }

  getInterfaceVoteFunctions(interfaceCode) {
    var arr = [];
    arr.push(<option key={this.state.currentBid[1]} value={this.state.currentBid[1]}>{this.state.currentBid[1]}</option>)
    return arr; 
  }

  pushParam(param) {
    let tempArr = this.state.currentParams;
    switch(this.state.currentTypeSelected) {
      case "addresses":
        tempArr['addresses'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "integers":
        tempArr['integers'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "strings":
        tempArr['strings'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "bytes":
        tempArr['bytes'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      default:
        return;
    }
  }

  pushChainParam(param, index) {
    let tempArr = this.state.currentParams;
    switch(this.state.currentTypeSelected) {
      case "addresses":
        tempArr['chain'][index]['addresses'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "integers":
        tempArr['chain'][index]['integers'].push(param.toString());
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "strings":
        tempArr['chain'][index]['strings'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      case "bytes":
        tempArr['chain'][index]['bytes'].push(param);
        this.setState({ 
          currentParams: tempArr
        });
        this.forceUpdate();
        break;
      default:
        return;
    }
  }

  getPushedParams() {
    var arr = [];
      for (let i = 0; i <= this.state.currentParams[this.state.currentTypeSelected].length; i++) {
        arr.push(<option key={i} value={this.state.currentParams[this.state.currentTypeSelected][i]}>{this.state.currentParams[this.state.currentTypeSelected][i]}</option>)
      }
  
      return arr;
  }

  

  getPushedChainParams(index) {
    var arr = [];
    if (this.state.currentTypeSelected === "addresses") {
      for (var j = 0; j < this.state.currentParams.chain[index][this.state.currentTypeSelected].length; j++) {
        var tempVar = this.state.currentParams.chain[index].integers[j];
        arr.push(
          <div>
            <div onClick={() => this.deleteChainBidParam(tempVar, index, j)} className="delete-param">X</div>
            <option className="parameter" value={this.state.currentParams.chain[index].addresses[j]}>{this.state.currentParams.chain[index].addresses[j]}</option>
          </div>
        )
      }
      
    }
    if (this.state.currentTypeSelected === "integers") {
      for (let j = 0; j < this.state.currentParams.chain[index][this.state.currentTypeSelected].length; j++) {
        var tempVar = this.state.currentParams.chain[index].integers[j];
        arr.push(
          <div>
            <div onClick={() => this.deleteChainBidParam(tempVar, index, j)} className="delete-param">X</div>
            <option className="parameter" value={this.state.currentParams.chain[index].integers[j]}>{this.state.currentParams.chain[index].integers[j]}</option>
          </div>
        )
      }
    }
    if (this.state.currentTypeSelected === "strings") {
      for (let j = 0; j < this.state.currentParams.chain[index][this.state.currentTypeSelected].length; j++) {
        var tempVar = this.state.currentParams.chain[index].strings[j];
        arr.push(
          <div>
            <div onClick={() => this.deleteChainBidParam(tempVar, index, j)} className="delete-param">X</div>
            <option className="parameter" value={this.state.currentParams.chain[index].strings[j]}>{this.state.currentParams.chain[index].strings[j]}</option>
          </div>
        )
      }
    }
    if (this.state.currentTypeSelected === "bytes") {
      for (let j = 0; j < this.state.currentParams.chain[index][this.state.currentTypeSelected].length; j++) {
        var tempVar = this.state.currentParams.chain[index].bytes[j];
        arr.push(
          <div>
            <div onClick={() => this.deleteChainBidParam(tempVar, index, j)} className="delete-param">X</div>
            <option className="parameter" value={this.state.currentParams.chain[index].bytes[j]}>{this.state.currentParams.chain[index].bytes[j]}</option>
          </div>
        )
      }
    }
  

      return arr;
  }

  getProposedChainParams(index) {
    var arr = [];
    if (this.state.currentTypeSelected === "addresses") {
      for (var j = 0; j < this.state.currentChainBidData[3].length; j++) {
        arr.push(
          <div>
            <option className="parameter" value={this.state.currentChainBidData[3][j]}>{this.state.currentChainBidData[3][j]}</option>
          </div>
        )
      }
      
    }
    if (this.state.currentTypeSelected === "integers") {
      for (var j = 0; j < this.state.currentChainBidData[4].length; j++) {
        arr.push(
          <div>
            <option className="parameter" value={this.state.currentChainBidData[4][j]}>{this.state.currentChainBidData[4][j]}</option>
          </div>
        )
      }
    }
    if (this.state.currentTypeSelected === "strings") {
      for (var j = 0; j < this.state.currentChainBidData[5].length; j++) {
        arr.push(
          <div>
            <option className="parameter" value={this.state.currentChainBidData[5][j]}>{this.state.currentChainBidData[5][j]}</option>
          </div>
        )
      }
    }
    if (this.state.currentTypeSelected === "bytes") {
      
    }
  

      return arr;
  }

  getPushedBidPropParams() {
    var arrPos = 0
    if (this.state.currentTypeSelected === "addresses") {arrPos = 2};
    if (this.state.currentTypeSelected === "integers") {arrPos = 3};
    if (this.state.currentTypeSelected === "strings") {arrPos = 4};
    if (this.state.currentTypeSelected === "bytes") {arrPos = 5};

    this.setState({pushedParamLength: this.state.currentParams[this.state.currentTypeSelected].length});
  }

  getPushedPropParams() {
    var arrPos = 0
    if (this.state.currentTypeSelected === "addresses") {arrPos = 2};
    if (this.state.currentTypeSelected === "integers") {arrPos = 3};
    if (this.state.currentTypeSelected === "strings") {arrPos = 4};
    if (this.state.currentTypeSelected === "bytes") {arrPos = 5};
    this.setState({pushedParamLength: this.state.currentBid[arrPos].length});
  }

  getBidParamList() {
    var arr = [];
    var arrPos = 0
    if (this.state.currentTypeSelected === "addresses") {arrPos = 2};
    if (this.state.currentTypeSelected === "integers") {arrPos = 3};
    if (this.state.currentTypeSelected === "strings") {arrPos = 4};
    if (this.state.currentTypeSelected === "bytes") {arrPos = 5};
    for (let i = 0; i < this.state.currentParams[this.state.currentTypeSelected].length; i++) {
      
      arr.push(
        <div>
          <div onClick={() => this.deleteBidParam(this.state.currentParams[this.state.currentTypeSelected][i])} className="delete-param">X</div>
          <option key={i} className="parameter" value={this.state.currentParams[this.state.currentTypeSelected][i]}>{this.state.currentParams[this.state.currentTypeSelected][i]}</option>
        </div>
      )
    }
    return arr;
  }

  deleteBidParam(param) {
    var tempArr = this.state.currentParams;
    for (let i = 0; i < tempArr[this.state.currentTypeSelected].length; i++) {
      if (tempArr[this.state.currentTypeSelected][i] === param) {
        var paramArr = tempArr[this.state.currentTypeSelected];
        paramArr.splice(i, 1);
        tempArr[this.state.currentTypeSelected] = paramArr;
        this.setState({currentParams: tempArr});
      }
    }
  }

  deleteChainBidParam(param, index, cI) {
    var tempArr = this.state.currentParams;

    for (let i = 0; i < tempArr['chain'][index][this.state.currentTypeSelected].length; i++) {
      if (tempArr['chain'][index][[this.state.currentTypeSelected]][i] === param) {
        var paramArr = tempArr['chain'][index][this.state.currentTypeSelected];
        paramArr.splice(i, 1);
        tempArr['chain'][index][this.state.currentTypeSelected] = paramArr;
        this.setState({currentParams: tempArr});
      }
    }
  }

  getParamList() {
    var arr = [];
    var arrPos = 0
    if (this.state.currentTypeSelected === "addresses") {arrPos = 2};
    if (this.state.currentTypeSelected === "integers") {arrPos = 3};
    if (this.state.currentTypeSelected === "strings") {arrPos = 4};
    if (this.state.currentTypeSelected === "bytes") {arrPos = 5};
    for (let i = 0; i < this.state.currentBid[arrPos].length; i++) {
      
      arr.push(<option key={i} className="parameter" value={this.state.currentBid[arrPos][i]}>{this.state.currentBid[arrPos][i]}</option>)
    }
    return arr;
  }

  getCurrentEthBlock = async () => {
    let blockNum = await this.governanceInstance.methods.getBlockNumber().call();
    if (blockNum > 0) {
        this.setState({currentEthBlock: blockNum})
    }
  }

  withdrawBidNyan = async () => {
    let withdrawn = await this.nyanVotingInstance.methods
                            .withdrawBidNyan().send({ from: this.accounts[0]});
  }

  showProposals() {
    var arr = [];
      for (let i = 0; i < this.state.proposals.length; i++) {
        arr.push(
          <div className="e-proposal">
            {this.state.currentBidAddress !== this.state.proposals[i]?
              <div className="view-prop" onClick={() => this.getBidView(this.state.proposals[i])}>VIEW</div>
              :
              <div className="view-prop" onClick={() => this.closeBidView(this.state.proposals[i])}>CLOSE</div>
            }
            <h5>Bid Address: {this.state.proposals[i]}</h5>
            {this.state.currentBidAddress === this.state.proposals[i]?
              <p className="bid-votes">Votes: {this.web3.utils.fromWei(this.state.currentBid[6])}</p> : null
            }
            {this.state.currentBidAddress === this.state.proposals[i]?
              <div className="">
              <div className="bid">
                <label for="functionCode">Interface:</label>
                <select id="functionCode" className="input-amount" disabled>
                  {/* <option label="ERC20">ERC20</option>
                  <option label="WETH">WETH</option>
                  <option label="Yearn">Yearn</option>
                  <option label="Uniswap">Uniswap</option> */}
                  {this.getInterfaces()}
                </select>
                <label for="functionName">Function:</label>
                <select disabled onChange={event => this.handleFunctionSelect(event.target.value)} id="functionName" className="input-amount">
                  {this.getInterfaceVoteFunctions(this.state.currentInterface)}
                </select>

                <h5>Info about function</h5>
                {this.state.bidInfoLoaded ?
                  <div>{this.state.currentBidInfo.mainBid[0]}</div>
                  :
                  null
                }
                

                <div className="var-box">
                  <div className="types">
                    <div onClick={() => this.selectTypeOption('addresses')} className={`types-op ${this.state.currentTypeSelected === "addresses"?"types-sel":null}`}>Addresses[]</div>
                    <div onClick={() => this.selectTypeOption('integers')} className={`types-op ${this.state.currentTypeSelected === "integers"?"types-sel":null}`}>Integers[]</div>
                    <div onClick={() => this.selectTypeOption('strings')} className={`types-op ${this.state.currentTypeSelected === "strings"?"types-sel":null}`}>Strings[]</div>
                    <div onClick={() => this.selectTypeOption('bytes')} className={`types-op ${this.state.currentTypeSelected === "bytes"?"types-sel":null}`}>Bytes[]</div>
                  </div>
                  <div className="values-area">
                    {this.state.pushedParamLength === 0 ? null : this.getParamList()}
                   
                    {this.state.currentParams === "integers"? 
                      <input 
                      
                      className="param-input" type="text" id="lname" name="lname">

                      </input> : null}
                    
                    <br></br>
                    
                  </div>
                </div>
                <input className="vote-input" onChange={event => this.updateVoteInput(event)} type="number" placeholder="Enter amount of votes"></input>
                <div className="propose" onClick={() => this.voteForBid(i)}>VOTE</div>
              </div>
              <div className="v-bar"></div>
              {
              this.state.hasBid ? 
              <div>
                {this.getChainView()}
              </div>
              :
              <div></div>
            }
            </div>
            : null}
          </div>
        )
      }
  
      return arr;
  }

  getChainBid = async (chainId) => {
    let chainBid = await this.nyanVotingInstance.methods.getChain(chainId).call();
    this.setState({currentChainBidData: chainBid, currentChainBid: chainId});
    let chainBidInfo = await this.getChainBidInfo(chainBid[1], chainBid[2], chainBid[3], chainBid[4], chainBid[5], []);
  }

  getChainView() {
    let arr = []
    for (let i = 0; i < this.state.currentBid[5].length; i++) {
      arr.push(
        <div className="chain-bid">
          <div className="show-chain-bid" onClick={() => this.getChainBid(this.state.currentBid[5][i])}>Show Chain Bid</div>
          {this.state.currentChainBid === this.state.currentBid[5][i] 
            ?
            <div className="bid">
                  <label for="functionCode">Interface:</label>
                  <select id="functionCode" className="input-amount"  disabled>
                    <option selected>{this.state.currentChainBidData[1]}</option>
                  </select>
                  <label >Function:</label>
                  <select className="input-amount" disabled>
                    <option selected>{this.state.currentChainBidData[2]}</option>
                  </select>

                  <h5>Info about function</h5>
                  {this.state.bidInfoLoaded ?
                    <div>{this.state.currentBidInfo.chain[0]}</div>
                    :
                    null
                  }
                  <div className="var-box">
                    <div className="types">
                      <div onClick={() => this.selectTypeOption('addresses')} className={`types-op ${this.state.currentTypeSelected === "addresses"?"types-sel":null}`}>Addresses[]</div>
                      <div onClick={() => this.selectTypeOption('integers')} className={`types-op ${this.state.currentTypeSelected === "integers"?"types-sel":null}`}>Integers[]</div>
                      <div onClick={() => this.selectTypeOption('strings')} className={`types-op ${this.state.currentTypeSelected === "strings"?"types-sel":null}`}>Strings[]</div>
                      <div onClick={() => this.selectTypeOption('bytes')} className={`types-op ${this.state.currentTypeSelected === "bytes"?"types-sel":null}`}>Bytes[]</div>
                    </div>
                    <div className="values-area">
                      {this.getProposedChainParams(i)}
                    </div>
                  </div>
                </div>
            :
            <div></div>
          }
        </div>
      )
    }
    return arr;
  }

  getChainParamList() {
    var arr = [];
    var arrPos = 0
    if (this.state.currentTypeSelected === "addresses") {arrPos = 2};
    if (this.state.currentTypeSelected === "integers") {arrPos = 3};
    if (this.state.currentTypeSelected === "strings") {arrPos = 4};
    if (this.state.currentTypeSelected === "bytes") {arrPos = 5};
    for (let i = 0; i < this.state.currentBid[arrPos].length; i++) {
      
      arr.push(<option key={i} className="parameter" value={this.state.currentBid[arrPos][i]}>{this.state.currentBid[arrPos][i]}</option>)
    }
    return arr;
  }

  getNyanAllowance = async () => {
    let _nyanAllowance = await this.nyanInstance.methods.allowance(this.accounts[0], this.nyanVotingInstance._address).call();
    if (_nyanAllowance > 0) {
        this.setState({nyanApproved: this.web3.utils.fromWei(_nyanAllowance.toString())})
    }
  }

  approveNyan = async () => {
    if (this.state.isNyanApproving) {
        return;
    }  
    this.setState({isNyanApproving: true});
    let amount = 33000;
    let approveStaking = await this.nyanInstance.methods.approve(this.nyanVotingInstance._address, this.web3.utils.toWei(amount.toString())).send({
        from: this.accounts[0]
    });
    
    if (approveStaking["status"]) {
        this.setState({isApproving: false, nyanApproved: this.web3.utils.toWei(amount.toString())});
        
    }
  }

  getCatnipAllowance = async () => {
    let _nyanAllowance = await this.catnipV2Instance.methods.allowance(this.accounts[0], this.nyanVotingInstance._address).call();
    if (_nyanAllowance > 0) {
        this.setState({catnipApproved: this.web3.utils.fromWei(_nyanAllowance.toString())})
    }
  }

  approveCatnip = async () => {
    if (this.state.isCatnipApproving) {
        return;
    }  
    this.setState({isCatnipApproving: true});
    let amount = 33000;
    let approveStaking = await this.catnipV2Instance.methods.approve(this.nyanVotingInstance._address, this.web3.utils.toWei(amount.toString())).send({
        from: this.accounts[0] 
    });
    
    if (approveStaking["status"]) {
        this.setState({isCatnipApproving: false, catnipApproved: this.web3.utils.toWei(amount.toString())});
        
    }
  }

  getGovernanceProposal = async () => {
    let proposalId = await this.governanceInstance.methods.proposals(0).call();
    let proposal = await this.governanceInstance.methods.getProposal(proposalId).call();
    this.setState({
      proposalId: proposalId,
      governanceQuestion:{
        question: proposal[0],
        options: proposal[2],
        optionImgs: proposal[3],
        optionVotes: proposal[4],
        voteEndBlock: proposal[5]
    }});
  }

  getNyanGovAllowance = async () => {
    let _nyanAllowance = await this.nyanInstance.methods.allowance(this.accounts[0], this.governanceInstance._address).call();
    if (_nyanAllowance > 0) {
        this.setState({nyanGovApproved: this.web3.utils.fromWei(_nyanAllowance.toString())})
    }
  }

  approveGovNyan = async () => {
    if (this.state.isNyanGovApproving) {
        return;
    }  
    this.setState({isNyanGovApproving: true});
    let amount = 33000;
    let approveStaking = await this.nyanInstance.methods.approve(this.governanceInstance._address, this.web3.utils.toWei(amount.toString())).send({
        from: this.accounts[0]
    });
    
    if (approveStaking["status"]) {
        this.setState({isNyanGovApproving: false, nyanGovApproved: this.web3.utils.toWei(amount.toString())});
        
    }
  }

  withdrawVotingNyan = async () => {
    this.setState({isWithdrawinGovNyan: true});
    let withdrawal = await this.governanceInstance.methods.withdrawNyan().send({
      from: this.accounts[0]
    });
    this.setState({isWithdrawinGovNyan: false});
  }

  loadOptions() {
    let arr = [];
    for (let i = 0; i < this.state.governanceQuestion.options.length; i++) {
      arr.push(
        <div className="each-g-op">
          <div onClick={() => this.voteForOption(i)} className="vote-governance">Vote</div>
          <div className="governance-vote-count">Votes: {this.web3.utils.fromWei(this.state.governanceQuestion.optionVotes[i])}</div>
          <div>{this.state.governanceQuestion.options[i]}</div>
          {this.state.governanceQuestion.optionImgs[i] != "" ?
            <img src={this.state.governanceQuestion.optionImgs[i]} />
            :
            null
          }
        </div>
      )
    }
    return arr;
  }

  voteForOption = async (index) => {
    let x = prompt("How many Nyan do you want to vote?", "0");
    let float = parseFloat(x);
    console.log(float, index, this.state.proposalId);
    let vote = await this.governanceInstance.methods.voteForProposal(this.web3.utils.toWei(float.toString()), index, this.state.proposalId).send({
      from: this.accounts[0]
  });
  }

  getMyGovernanceVotes = async () => {
    let myVotes = await this.governanceInstance.methods.getVoteCount(this.accounts[0]).call();
    console.log(myVotes);
    this.setState({myGovernanceVotes: this.web3.utils.fromWei(myVotes.toString())});
  }

  getMyNyanVotes = async () => {
    console.log(this.nyanVotingInstance.methods);
    let myVotes = await this.nyanVotingInstance.methods.getVotes(this.accounts[0]).call();
    console.log(myVotes);
    this.setState({myNyanVotes: this.web3.utils.fromWei(myVotes.toString())});
  }

  getContractData = async () => {
    let arr = []
    for (let i = 0; i < this.state.contractList.length; i++) {
      this.oldVotingInstance = new this.web3.eth.Contract(
        nyanVoting.abi,
        this.state.contractList[i]
      );
      let nyanLocked = await this.oldVotingInstance.methods.getBidNyanStaked(this.accounts[0]).call();
      arr.push(
        <div>Nyan Locked: {nyanLocked}</div>
        )
      if (nyanLocked) {
        
      }
    }
  }

  executeBid = async () => {
    let bidExec = await this.nyanVotingInstance.methods.executeBid().send({
      from: this.accounts[0]
    });
  }

  componentDidMount = async () => {
    try {
      this.web3 = getWeb3Var();
        
      // // Get network provider and web3 instance.
     
      // // Use web3 to get the user's accounts.
      this.accounts = await this.web3.eth.getAccounts();
    
      // // Get the contract instance.
      this.networkId = await this.web3.eth.net.getId();

      this.nyanVotingInstance = new this.web3.eth.Contract(
        nyanVoting.abi,
        process.env.REACT_APP_VOTING_TOKEN_CONTRACT_ADDRESS
      );

      this.nyanVotingDataInstance = new this.web3.eth.Contract(
        nyanVotingData.abi,
        process.env.REACT_APP_VOTING_TOKEN_CONTRACT_ADDRESS
      );


      this.nyanInstance = new this.web3.eth.Contract(
        NyanToken.abi,
        process.env.REACT_APP_NYAN_TOKEN_CONTRACT_ADDRESS
      );

      this.catnipInstance = new this.web3.eth.Contract(
        CatnipToken.abi,
        process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS
      );

      this.catnipV2Instance = new this.web3.eth.Contract(
        CatnipToken.abi,
        process.env.REACT_APP_CATNIPV2_TOKEN_CONTRACT_ADDRESS
      );

      this.governanceInstance = new this.web3.eth.Contract(
        Governance.abi,
        "0x4aB481D6A12fE30d839Ae0D3a626E7C51778C400"
      );
     
      // this.catnipInstance = new this.web3.eth.Contract(
      //   CatnipToken.abi,
      //   process.env.REACT_APP_CATNIP_TOKEN_CONTRACT_ADDRESS
      // );

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({
        voteOption: "Info",
        hasBid: false,
        interfaces: [
          {
            code: 'erc20',
            functions: ["", "totalSupply", "balanceOf", "transfer", "allowance", "approve", "transferFrom"]
          },
          {
            code: 'ETH',
            functions: ["","sendETH"]
          },
          {
            code: 'fund',
            functions: ["","setVotingAddress", "setConnectorAddress", "setNewFundAddress", "setNyanAddress", "setCatnipAddress", "setDNyanAddress", "setBalanceLimit"]
          },
          {
            code: 'voting',
            functions: ["", "setConnector", "setFundAddress", "setIsRewardingCatnip", "setRewardsContract", "setVotingPeriodBlockLength", "setNyanAddress", "setCatnipAddress", "setDNyanAddress", "distributeFunds", "burnCatnip"]
          },
          {
            code: 'connector',
            functions: ["", "setVotingAddress", "setFundAddress", "setUniswapAddress", "addToTokenList", "transferToFund", "setUniswapAddress", "sendToNewContract"]
          },
          {
            code: 'nyanV2',
            functions: ["", "swapNyanV1", "stakeNyanV2LP", "unstakeNyanV2LP", "stakeDNyanV2LP", "unstakeDNyanV2LP", "addNyanAndETH", "claimETHLP", "initializeV2ETHPool"]
          },
          {
            code: 'uniV2',
            functions: ["", "addLiquidity", "addLiquidityETH", "removeLiquidity", 
                        "removeLiquidityETH", "removeLiquidityETHSupportingFeeOnTransferTokens", 
                        "swapExactTokensForTokens", "swapTokensForExactTokens", "swapExactETHForTokens", 
                        "swapExactTokensForETH", "swapETHForExactTokens", "swapExactTokensForTokensSupportingFeeOnTransferTokens", 
                        "swapExactETHForTokensSupportingFeeOnTransferTokens", "swapExactTokensForETHSupportingFeeOnTransferTokens", 
                        "feeTo", "feeToSetter", "getPair", "allPairs", "allPairsLength", "createPair", "setFeeTo",
                        "setFeeToSetter", "setFeeToSetter", "setFeeToSetter", "setFeeToSetter"]
          },
          {
            code: "weth",
            functions: ["", "wethDeposit", "wethWithdraw"]
          },
          {
            code: "core",
            functions: ["", "deposit", "depositFor", "setAllowanceForPoolToken",
                        "withdrawFrom", "withdraw", "emergencyWithdraw"]
          },
          {
            code: "yearn",
            functions: ["", "cConvert", "yicWithdraw", "yicBalanceOf", "yicEarn", "yicWant",
                        "yicRewards", "yicVaults", "ymMint", "yosaSwap", "yosaGetExpectedReturn",
                        "yStratWant", "yStratDeposit", "yStratWithdraw", "yStratWithdraw1",
                        "yStratWithdrawAll", "yStratBalanceOf", "yERCDeposit", "yERCWithdraw",
                        "yERCPricePerFullShare", "yVaultsDeposit", "yVaultsDepositAll", "yVaultsWithdraw",
                        "yVaultsWithdrawAll", "yVaultsPricePerFullShare"]
          }
        ]
      });
      this.getVotingRound();
      this.getVotingStartEndBlocks();
      this.getNyanAllowance();
      this.getCatnipAllowance();
      this.getNyanGovAllowance();
      this.getMyGovernanceVotes();
      this.getMyNyanVotes();
      this.getCurrentEthBlock();
      this.getLockedNyan();
      

    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Check console for details.`,
      );
      console.error(error);
    }
  };
  

  render() {
    return (
      <div className="modal">
        <div className="modal_content">
          <span className="close" onClick={this.handleClick}>
            &times;
          </span>
          <div onClick={() => this.selectVoteOption('Info')} className={`vote-option  ${this.state.voteOption === "Info"? "v-op-selected": ""}`}>Info</div>
          <div onClick={() => this.selectVoteOption('Bid')} className={`vote-option  ${this.state.voteOption === "Bid"? "v-op-selected": ""}`}>Bid</div>
          <div onClick={() => this.selectVoteOption('Vote')} className={`vote-option  ${this.state.voteOption === "Vote"? "v-op-selected": ""}`}>Vote</div>
          <div onClick={() => this.selectVoteOption('Claim')} className={`vote-option  ${this.state.voteOption === "Claim"? "v-op-selected": ""}`}>Claim</div>

          {this.state.voteOption === "Info"?
            <div>
              <h3>Welcome to the steering wheel of a multi-million dollar machine</h3>
              <p>
                In this system, Nyan holders will be able to freely govern the protocol as they wish.
                The voting and function calls operate on a bidding system, in which the bid with the most votes at the 
                end of a round will be executed. A bid can be a call to a contract, or a combination of calls to 
                one or multiple contracts, all within the same transaction.
              </p>

              <h3>What are Bids?</h3>
              <p>
                Glad you asked! Bids are a set of instructions that relay what functions to call with the Voting Connector contract.
                A bid is composed of the Bidder's address, a unique bidId, and a series of arrays with types including Integers, Strings,
                and Bytes. With these data fields, anyone will be able to construct a bid with its own set of parameters for the function
                to be called.
                <br></br>
                <br></br>
                Additionally, bids can be chained. No, this isn't street fighter. An additional bid can be chained to an initial bid. This 
                additional bid is known as a chain bid. A chain bid requires an initial bid in order to be created. With chains, much more complex
                transactions can be created. It's a Pandora's box to be honest.
              </p>

              <h3>How does voting occur?</h3>
              <p>
                Voting for bids occurs within periods known as voting rounds. Before each round begins, Nyan holders can construct a bid and propose it
                in order to have it ready for voting.
                <br></br>
                <br></br>
                Other Nyan holders will vote using Nyan as a proof of collateral and Catnip as a fee. These fees will vary based on the amount of votes an individual 
                is attempting to place in any one round. The more votes, the higher the Catnip cost. With this route, the cost of voting should be negligible for
                smaller holder.
                <br></br>
                <br></br>
                The current minimum Nyan needed to vote is 1 Nyan. However, this can be changed by voters.
              </p>

              <h3>How does the machine maintain value?</h3>
              <p>
                An reasonable thought that any onlooker would immediately have is: Is the protocol dependent on turning a profit? Fortunately, the 
                answer is no. The Nyan machine will never have to turn a single profit in order to maintain value for Nyan holders.
                <br></br>
                <br></br>
                Here's why. All the funds held by the funding contract were generated by Nyan holders that have either staked their Nyan or Catnip Liquidity tokens.
                This means that those funds can be considered as speculated into existence. Now in order to make sure that value will always outpace minting and speculation, 
                the goal of the Nyan protocol is continuously burn Catnip used as a fee for voting.
                <br></br>
                <br></br>
                Half of this Catnip will also be swapped for dNyan in order to provide rewards for individuals that provide liquidity to Nyan. Providing Nyan/Eth Liquidity will be a 
                good option for smaller holders that may not want to participate in voting. The Nyan/Eth LP tokens will be staked
              </p>

              <h3>How do distributions work?</h3>
              <p>
                Voters decide when and what to distrubute, with certain limitations. Distributions can be called once every 2 days maximum. The distribution can be for any token
                held by the funding address. Catnip and dNyan distributions are limited to 10% of their available amount in any one transaction. Distributions of other assets can use all of the
                available asset.
              
                <br></br>
                <br></br>
                In order to claim their proportion of the distributed asset, claimers must stake their Nyan or Catnip Liquidity tokens. The tokens will only be released when the distribution period
                has ended. This is to prevent users from unfairly claiming twice.
              </p>

              <h3>Is the voting contract fool-proof?</h3>
              <p>
                No contract is fool-proof. The Nyan voting contracts, however are built with modularity in mind. Each contract can be upgraded, replaced and reconnected. Voters will only have to vote for 
                a bid that reconnects the contract to the rest of the ecosystem. Built this way, Nyan can continuously be updated by it's community without the need for a central developer. The voters can also 
                vote for a transfer that pays an external auditor to look over any code in the ecosystem.
                <br></br>
                <br></br>
                To get a better technical look at the upcoming voting contracts, visit the &nbsp; 
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/geass-zero/nyan.finance/tree/master/contracts/NyanFund">Nyan Github</a> &nbsp;
                and look over the voting contract files. The files in the github are not the most current version, please do not
                fork them into production. There are several bugs and mistakes that have been done in the unpushed version.
              </p>
              <br></br>
              <br></br>
              <h3>The contract is almost ready and a V2 design for the whole site is coming soon. This is just early UI to get everyone familiar with the system.
                Please make sure to visit our telegram to ask any questions!</h3>
            </div>
          : null}
          {this.state.voteOption === "Bid"? 
            <div className="bid-creator">
              <div className="">
                <div className="bid">
                  <label for="functionCode">Interface:</label>
                  <select onChange={event => this.handleInterfaceSelect(event.target.value)} id="functionCode" className="input-amount">
                    {/* <option label="ERC20">ERC20</option>
                    <option label="WETH">WETH</option>
                    <option label="Yearn">Yearn</option>
                    <option label="Uniswap">Uniswap</option> */}
                    {this.getInterfaces()}
                  </select>
                  <label for="functionName">Function:</label>
                  <select onChange={event => this.handleFunctionSelect(event.target.value)} id="functionName" className="input-amount">
                    {this.getInterfaceFunctions(this.state.currentInterface)}
                  </select>

                  <h5>Info about function</h5>
                  <div className="var-box">
                    <div className="types">
                      <div onClick={() => this.selectTypeOption('addresses')} className={`types-op ${this.state.currentTypeSelected === "addresses"?"types-sel":null}`}>Addresses[]</div>
                      <div onClick={() => this.selectTypeOption('integers')} className={`types-op ${this.state.currentTypeSelected === "integers"?"types-sel":null}`}>Integers[]</div>
                      <div onClick={() => this.selectTypeOption('strings')} className={`types-op ${this.state.currentTypeSelected === "strings"?"types-sel":null}`}>Strings[]</div>
                      <div onClick={() => this.selectTypeOption('bytes')} className={`types-op ${this.state.currentTypeSelected === "bytes"?"types-sel":null}`}>Bytes[]</div>
                    </div>
                    <div className="values-area">
                      {this.state.pushedParamLength === 0 ? null : this.getBidParamList()}
                     
                      {this.state.currentParams === "integers"? 
                        <input 
                        
                        className="param-input" type="text" id="lname" name="lname">

                        </input> : null}
                      {this.state.currentParams != "integers"? 
                        <input 
                        id="non-int"
                        placeholder="Enter your parameter"
                        onChange={event => this.updateParamStringInput(event.target.value)}
                        className="param-input" type="text" id="lname" name="lname">

                        </input> : null}
                      <br></br>
                      {this.state.currentTypeSelected==="integers" ? <div className="add-value" onClick={event=> {this.pushParam(this.state.stringInput)}}>Add Parameter</div> : null}
                      {this.state.currentTypeSelected!="integers" ? <div className="add-value" onClick={event=> {this.pushParam(this.state.stringInput)}}>Add Parameter</div> : null}
                    </div>
                  </div>
                  <div className="propose" onClick={() => this.proposeBid()}>PROPOSE</div>
                </div>
                <div className="v-bar"></div>
              </div>
              {this.loadChain()}
              {this.state.currentBid != {} ? <div className="add-chain" onClick={() => this.addChain()}>Add Chain</div> : null}
            </div>
          : null}
          {this.state.voteOption === "Vote"?
            <div>
              <div>
                <div className={`vote-section ${this.state.voteSection === "Fund" ? "v-s-selected" : null}`} onClick={() => this.changeVoteSection("Fund")}>Fund</div>
                <div className={`vote-section ${this.state.voteSection === "Governance" ? "v-s-selected" : null}`} onClick={() => this.changeVoteSection("Governance")}>Governance</div>
              </div>
              {this.state.voteSection === "Fund"?
              // Hedge fund voting
              <div>
              <h3>VOTING ROUND #{this.state.votingRound}</h3>
              <h5>VOTING ROUND START BLOCK: {this.state.currentVotingStartBlock}</h5>
              <h5>VOTING ROUND END BLOCK: {this.state.currentVotingEndBlock}</h5>
              <h5>MY CURRENT VOTECOUNT: {this.state.myNyanVotes}</h5>
              <a target="_blank" rel="noopener noreferrer" href="https://etherscan.io/address/0x2c9728ad35C1CfB16E3C1B5045bC9BA30F37FAc5#code" >
                <h4>View Nyan Fund</h4>
              </a>
              <div className="exec-bid" onClick={() => this.executeBid()}>EXECUTE TOP BID</div>
              <h2 className="prop-title">PROPOSALS</h2>
              <div className="prop-bar"></div>

              { this.state.catnipApproved === 0 ?
                <div>
                  {this.state.catnipApproved > 0 ? null : <div onClick={() => this.approveCatnip()} className="approve-catnip">Approve Catnip</div>}
                </div>
                 :
                this.state.proposals.length < 0 ? 
                <div className="no-bids">No current bids</div> 
                : this.showProposals()
              }
              
            </div>
            :
            // Non chain voting
            <div>           
              
              <h2>{this.state.governanceQuestion.question}</h2>
              <h3>Votes Left: {this.state.myGovernanceVotes}</h3>
              <p>Voting ends on Block# {this.state.governanceQuestion.voteEndBlock}</p>
              {this.loadOptions()}
            </div>
           }
            </div>
          : null}
          {this.state.voteOption === "Claim"?
            <div>
              <h4>You are not in a distribution period.</h4>

              <div className="locked-nyan-stats">
          <     div className="stat-op">Locked Nyan: {this.state.lockedNyan}</div>
                <div className="stat-op">Unlock Block: 0</div>
              </div>
              <div className="claim-withdraw" onClick={() => this.withdrawBidNyan() }>Withdraw Locked Nyan</div>
              {/* <div>
                {this.getContractData()}
              </div> */}
            </div>
          :null}
          {/* <h1>PUMP FUND</h1>
            <h3>Let's build a whale!</h3>

            <div>
                <p>NYAN is introducing a publically governed Uniswap Hedge fund.</p>
            </div>
            
            <div>
                <p>20% of all minted Catnip goes to a funding contract every few days.</p>
            </div>
          
            <div>
                <p>NYAN holders will be able to vote on which Uniswap token the contract will swap for. </p>
            </div>

            <div>
              <p>The contract will hold the token for a specified amount of blocks or until NYAN holders
                    vote for a swap back to Catnip</p>
            </div>
            
            <div>
                <p>During a swap back to Catnip, the resulting Catnip will
                    be proportionately distributed to NYAN holders.</p>
            </div>

            <div className="vote-nyan"></div>

            <div className="button launch-date">Voting starts late September</div> */}
        </div>
      </div>
    );
  }
}
